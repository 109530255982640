import axios from 'axios'
// eslint-disable-next-line import/named
import { MutationTree } from 'vuex'
import { Entity } from '~/store/lib/entity'
import NuxtRuntimeConfig = global.NuxtRuntimeConfig

export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Timestamp: any
}

export interface ProductRelated {
  maxItems: Scalars['Int']
  minItems: Scalars['Int']
  products: string[]
  title: Scalars['String']
}

export interface ProductOccupancy {
  minimum: Scalars['Int']
  maximum: Scalars['Int']
}

export type ProductPaymentOpts = {
  id: Scalars['ID']
  monthly?: Scalars['Boolean']
  available: Scalars['Int']
}

export interface Product extends Entity {
  __typename: 'Product'
  id: Scalars['ID']
  description: Scalars['String']
  element: Scalars['String']
  groupName: Scalars['String']
  serviceDates: {
    start: Scalars['Timestamp']
    end: Scalars['Timestamp']
    kind: Scalars['String']
  }
  pricing: {
    deposit: {
      amount: Scalars['Int']
      currency: Scalars['String']
    }
    price: {
      amount: Scalars['Int']
      currency: Scalars['String']
    }
    balanceDue: Scalars['Timestamp']
  }
  occupancy: ProductOccupancy
  inventory: {
    type: Scalars['String']
    available: Scalars['Int']
  }
  relatedProducts: ProductRelated[]
  extended: any
}

export interface Related {
  maxItems: Scalars['Int']
  minItems: Scalars['Int']
  products: string[]
  title: Scalars['String']
}

export interface ProductPaxAssignment {
  provider?: Scalars['String']
  sku: Scalars['String']
  passenger: Scalars['Int']
  passengers: any[]
}
export interface SummaryOption {
  item: Product
  count: Scalars['Int']
}

export interface ValidDiscount {
  id: Scalars['ID']
  code: Scalars['String']
  name: Scalars['String']
  isExclusive: Scalars['Boolean']
  isPerPassenger: Scalars['Boolean']
  isPercentage: Scalars['Boolean']
  validate: {
    value: {
      amount: Scalars['Int']
      currency: Scalars['String']
    }
  }
  value: Scalars['Int']
}

export interface ProductState {
  loading: Scalars['Boolean']
  product: Product | null
  products: ProductPaxAssignment[] | null
  related: Product[] | null
  paymentOpts: ProductPaymentOpts[] | null
  optionsDepositTotal: Scalars['Int']
  optionsTotal: Scalars['Int']
  depositTotal: Scalars['Int']
  discountsTotal: Scalars['Int']
  discounts: ValidDiscount[] | null
  clearDiscount: Scalars['Boolean']
  total: Scalars['Int']
  notes: any
}

export const state = (): ProductState => ({
  loading: true,
  product: null,
  products: null,
  // Once the API calls run: The related section
  // contains the returned data version.
  //
  related: null,
  paymentOpts: null,
  optionsDepositTotal: 0,
  optionsTotal: 0,
  depositTotal: 0,
  discountsTotal: 0,
  discounts: null,
  clearDiscount: false,
  total: 0,
  notes: [
    {
      title: 'Original Booking Note',
      content: '',
    },
  ],
})

const mutations: MutationTree<ProductState> = {
  /**
   * Reset the product details
   * @param state
   **/
  resetState(state: ProductState) {
    state.product = null
    state.products = null
    state.related = null
    state.paymentOpts = null
    state.optionsDepositTotal = 0
    state.optionsTotal = 0
    state.depositTotal = 0
    state.discountsTotal = 0
    state.discounts = null
    state.total = 0
    state.notes = [
      {
        title: 'Original Booking Note',
        content: '',
      },
    ]
  },
  // Custom loading feature.
  //
  startLoading(state: ProductState) {
    state.loading = true
  },
  stopLoading(state: ProductState) {
    state.loading = false
  },
  // Initialise product.
  //
  setProduct(
    state: ProductState,
    { product, providerId }: { product: Product; providerId: string }
  ) {
    state.product = product
    state.products = []
    state.products.push({
      provider: providerId,
      sku: product.id,
      passenger: 0,
      passengers: [`*`],
    })
  },
  // Passenger assignment.
  //
  setPassengerAssignment(
    state: ProductState,
    assignment: ProductPaxAssignment
  ) {
    // Initialise array.
    //
    if (!state.products) {
      state.products = []
    }
    // Check for existing product.
    //
    const idx = state.products!.findIndex((a) => a.sku === assignment.sku)
    if (idx !== -1) {
      state.products![idx].passengers?.push(assignment.passenger)
    } else {
      state.products!.push(assignment)
    }
  },
  unsetPassengerAssignment(
    state: ProductState,
    assignment: ProductPaxAssignment
  ) {
    // Check for existing passenger.
    //
    const idx = state.products!.findIndex(
      (a) =>
        a.sku === assignment.sku && a.passengers?.includes(assignment.passenger)
    )
    const idy = state.products!.findIndex((a) => a.sku === assignment.sku)
    if (idx !== -1) {
      const assignmentIndex = state.products![idx].passengers.findIndex(
        (p) => p === assignment.passenger
      )
      state.products![idx].passengers?.splice(assignmentIndex, 1)
      if (idy !== -1 && state.products![idy].passengers?.length === 0) {
        state.products!.splice(idy, 1)
      }
    } else {
      return false
    }
  },
  // Process related products.
  //
  setRelatedItems(state: ProductState, related: Product) {
    // Check for existing product.
    //
    if (state.related) {
      const idx = state.related!.findIndex((r) => r.id === related.id)
      if (idx === -1) {
        state.related!.push(related)
      }
    } else {
      state.related = []
      state.related!.push(related)
    }
  },

  updateBookingNotes(
    state: ProductState,
    { content, index }: { content: string; index: number }
  ) {
    // @ts-ignore
    state.notes[index].content = content
  },

  updateProductPaymentsOpts(state: ProductState, product: ProductPaymentOpts) {
    state.paymentOpts = []
    const idx = state.paymentOpts!.findIndex((p) => p.id === product.id)
    if (idx !== -1) {
      state.paymentOpts![idx] = product
    } else {
      state.paymentOpts!.push(product)
    }
  },
  applyDiscount(state: ProductState, discount: ValidDiscount) {
    if (state.discounts) {
      const idx = state.discounts!.findIndex((d) => d.id === discount.id)
      if (idx !== -1) {
        state.discounts![idx] = discount
      } else {
        state.discounts!.push(discount)
      }
    } else {
      state.discounts = []
      state.discounts!.push(discount)
    }
  },
  removeDiscount(state: ProductState, discount: ValidDiscount) {
    const idx = state.discounts!.findIndex((d) => d.id === discount.id)
    if (idx !== -1) {
      state.discounts!.splice(idx, 1)
    } else {
      return false
    }
  },
  clearDiscount(state: ProductState, status: boolean) {
    state.clearDiscount = status
  },
  // Manage totals.
  //
  updateTotal(state: ProductState, total: number) {
    state.total = total
  },
  updateDepositTotal(state: ProductState, total: number) {
    state.depositTotal = total
  },
  addToDepositTotal(state: ProductState, total: number) {
    state.optionsDepositTotal = state.optionsDepositTotal + total
  },
  subtractFromDepositTotal(state: ProductState, total: number) {
    state.optionsDepositTotal = state.optionsDepositTotal - total
  },
  addToDiscountTotal(state: ProductState, total: number) {
    state.discountsTotal = state.discountsTotal + total
  },
  subtractFromDiscountTotal(state: ProductState, total: number) {
    state.discountsTotal = state.discountsTotal - total
  },
  addToOptionsTotal(state: ProductState, total: number) {
    state.optionsTotal = state.optionsTotal + total
  },
  subtractFromOptionsTotal(state: ProductState, total: number) {
    state.optionsTotal = state.optionsTotal - total
  },
  // Used on product reset.
  //
  clearRelated(state: ProductState) {
    state.related = null
  },
}

const actions = {
  async initProduct(
    { commit }: { commit: Function },
    {
      config: { apiHost, providerId },
      sku,
    }: { config: NuxtRuntimeConfig; sku: string }
  ) {
    const skuPath = apiHost + '/products/' + providerId + '/' + sku
    await axios.get(skuPath).then((response) => {
      const productData = response.data
      commit('setProduct', { product: productData, providerId })
      commit('updateTotal', productData.pricing.price.amount)
      commit('updateDepositTotal', productData.pricing.deposit.amount)
      commit('stopLoading')
    })
  },
  setAssignment(
    { commit }: { commit: Function },
    { provider, sku, pax }: { provider: string; sku: string; pax: number }
  ) {
    const assignment: ProductPaxAssignment = {
      provider,
      sku,
      passenger: pax,
      passengers: [pax],
    }
    commit('setPassengerAssignment', assignment)
  },
  unsetAssignment(
    { commit }: { commit: Function },
    { provider, sku, pax }: { provider: string; sku: string; pax: number }
  ) {
    const assignment: ProductPaxAssignment = {
      provider,
      sku,
      passenger: pax,
      passengers: [],
    }
    commit('unsetPassengerAssignment', assignment)
  },
}

const getters = {
  getProducts: (state: any): Product => state.products,
  getProduct: (state: any): Product => state.product,
  getRelated: (state: any): Product => state.related,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
