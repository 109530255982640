import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6bef8b6c = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _c2ce1d14 = () => interopDefault(import('../pages/booking/index.vue' /* webpackChunkName: "pages/booking/index" */))
const _c5e12d38 = () => interopDefault(import('../pages/account/profile/index.vue' /* webpackChunkName: "pages/account/profile/index" */))
const _ec58c4ec = () => interopDefault(import('../pages/account/reset/index.vue' /* webpackChunkName: "pages/account/reset/index" */))
const _0c8ccd90 = () => interopDefault(import('../pages/account/setup/index.vue' /* webpackChunkName: "pages/account/setup/index" */))
const _66ca6c78 = () => interopDefault(import('../pages/account/reset/confirm.vue' /* webpackChunkName: "pages/account/reset/confirm" */))
const _2f6b437c = () => interopDefault(import('../pages/account/booking/_id.vue' /* webpackChunkName: "pages/account/booking/_id" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _6bef8b6c,
    name: "account"
  }, {
    path: "/booking",
    component: _c2ce1d14,
    name: "booking"
  }, {
    path: "/account/profile",
    component: _c5e12d38,
    name: "account-profile"
  }, {
    path: "/account/reset",
    component: _ec58c4ec,
    name: "account-reset"
  }, {
    path: "/account/setup",
    component: _0c8ccd90,
    name: "account-setup"
  }, {
    path: "/account/reset/confirm",
    component: _66ca6c78,
    name: "account-reset-confirm"
  }, {
    path: "/account/booking/:id?",
    component: _2f6b437c,
    name: "account-booking-id"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
