import createPersistedState from 'vuex-persistedstate'

export default ({ store }) => {
  createPersistedState({
    // Switch storage type, default: Local Storage.
    //
    storage: window.sessionStorage,
    paths: ['sku', 'step', 'booking', 'account'],
  })(store)
}
