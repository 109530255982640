
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { mixin } from '~/mixins/global'
import Btn from '~/components/Btn.vue'
import { ModalItem } from '~/store/account/accountDetails'

@Component({
  components: { Btn },
})
export default class Modal extends Vue {
  @Prop() readonly items?: ModalItem[]
  @Prop() readonly title?: string
  @Prop() readonly message?: string
  @Prop() readonly type!: string
  @Prop() readonly size!: string

  getUtcDate(date: any) {
    const newDate = mixin.rkGetUtcDate(date)
    return newDate ? mixin.rkFormatLocaleString(newDate?.toISOString()) : null
  }

  closeModal() {
    this.$emit('eventCloseModal')
    this.$store.commit('account/accountDetails/updateOverlay', {
      overlayStatus: false,
      overlayType: 'modal',
    })
  }
}
