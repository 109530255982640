import axios from 'axios'
// eslint-disable-next-line import/named
import { MutationTree } from 'vuex'
import { Booking, Transaction, TransactionStatus } from '~/store/lib/actions'
import NuxtRuntimeConfig = global.NuxtRuntimeConfig

export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Timestamp: any
}

export interface PaymentState {
  bookingAttempts: Scalars['Int']
  accountProcessing: Scalars['Boolean']
  transaction: TransactionStatus | null
  altBilling: Scalars['Boolean'] | false
}

export const state = (): PaymentState => ({
  bookingAttempts: 0,
  accountProcessing: false,
  transaction: null,
  altBilling: false,
})

const mutations: MutationTree<PaymentState> = {
  resetBookingAttempts(state: PaymentState) {
    state.bookingAttempts = 0
  },
  updateBookingAttempts(state: PaymentState) {
    state.bookingAttempts = state.bookingAttempts + 1
  },
  updateProcessing(state: PaymentState, processing: boolean) {
    state.accountProcessing = processing
  },
  updateTransaction(state: PaymentState, transaction: TransactionStatus) {
    state.transaction = transaction
  },
  changeBillingStatus(state: PaymentState, altBilling: boolean) {
    state.altBilling = altBilling
  },
}

const actions = {
  async createTransaction(
    { commit }: { commit: Function },
    {
      config: { apiHost },
      booking,
      transaction,
    }: { config: NuxtRuntimeConfig; booking: Booking; transaction: Transaction }
  ) {
    const bookPath = apiHost + '/book/' + booking.id + '/transactions'
    await axios
      .post(bookPath, transaction)
      .then((response) => {
        const data = {
          id: response.data.id,
          state: 'Created',
        }
        commit('updateTransaction', data)
      })
      .catch(() => {
        commit('updateProcessing', false)
      })
  },
  async getTransaction(
    { commit }: { commit: Function },
    {
      config: { apiHost },
      transactionId,
    }: { config: NuxtRuntimeConfig; transactionId: string }
  ) {
    const bookPath = apiHost + '/transactions/' + transactionId
    await axios
      .get(bookPath)
      .then((response) => {
        const data = {
          id: transactionId,
          state: response.data.status,
        }
        commit('updateTransaction', data)
      })
      .catch(() => {
        commit('updateProcessing', false)
      })
  },

  async cancelContract(
    { commit }: { commit: Function },
    {
      config: { apiHost },
      booking,
      token,
    }: { config: NuxtRuntimeConfig; booking: string; token: string }
  ) {
    const url = `${apiHost}/account/bookings/${booking}/contract/cancel`
    const headers = {
      Authorization: `Bearer ${token}`,
    }
    await axios.post(url, null, { headers }).catch(() => {
      commit('updateProcessing', false)
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
