// eslint-disable-next-line import/named
import { GetterTree } from 'vuex'
// @ts-ignore
import { getData } from 'country-list'
import { sortBy } from 'lodash'

export interface Country {
  name: string
  code: string
}

export interface CountryState {
  countries: Country[]
}

function state(): CountryState {
  return {
    countries: sortBy(getData(), [(c) => c.name]),
  }
}

const getters: GetterTree<CountryState, any> = {
  countries: (state: CountryState): Country[] => state.countries,
}

export default {
  namespaced: true,
  state,
  getters,
}
