
import Vue from 'vue'
import { mapState } from 'vuex'
import Component from 'vue-class-component'
import { entries } from 'lodash'
import { Watch } from 'vue-property-decorator'
import {
  BookingOutput,
  Reservation,
  ModalItem,
  Overlay,
  User,
} from '~/store/account/accountDetails'
import Modal from '~/components/Modal.vue'
import Btn from '~/components/Btn.vue'

@Component({
  components: {
    Modal,
    Btn,
  },
  computed: {
    ...mapState('account/accountDetails', [
      'user',
      'token',
      'processNotifications',
      'loadingNotifications',
      'bookingListFull',
      'overlay',
    ]),
  },
})
export default class AppHeader extends Vue {
  readonly bookingListFull!: BookingOutput[]
  readonly processNotifications!: boolean
  readonly loadingNotifications!: boolean
  readonly overlay!: Overlay
  readonly token!: string
  readonly user?: User

  showNotifications: boolean = false
  bookingFullStatus: ModalItem[] = []
  bookingData: BookingOutput[] = []

  notifications: number = 0

  toggleModal() {
    this.showNotifications = !this.showNotifications
    if (this.showNotifications) {
      this.$store.commit('account/accountDetails/updateOverlay', {
        overlayStatus: true,
        overlayType: 'modal',
      })
    }
  }

  processBookingNotifications() {
    this.bookingFullStatus = []
    entries(this.bookingListFull).forEach((entry: any) => {
      const [, value] = entry
      entries(value.passengers).forEach((subEntry: any) => {
        const [, subValue] = subEntry
        entries(subValue).forEach((pax: any) => {
          const key = pax[0]
          const val = pax[1]
          const idx = this.bookingData!.findIndex((b) => b.id === value.id)
          if (
            !val &&
            key !== 'telephone' &&
            key !== 'customer' &&
            key !== 'cancelledAt' &&
            idx === -1
          ) {
            this.bookingData.push(value)
          }
          if (key === 'notes' && idx === -1) {
            if (val.length === 0) {
              this.bookingData.push(value)
            } else {
              entries(val).forEach((note: any) => {
                const noteBookingIndex = this.bookingData!.findIndex(
                  (b) => b.id === value.id
                )
                const [, noteVal] = note
                if (
                  (noteVal &&
                    noteVal.title === 'Outbound Flight' &&
                    noteVal.content === null &&
                    noteBookingIndex === -1) ||
                  (noteVal &&
                    noteVal.title === 'Outbound Flight' &&
                    noteVal.content.length === 0 &&
                    noteBookingIndex === -1) ||
                  (noteVal &&
                    noteVal.title === 'Inbound Flight' &&
                    noteVal.content === null &&
                    noteBookingIndex === -1) ||
                  (noteVal &&
                    noteVal.title === 'Inbound Flight' &&
                    noteVal.content.length === 0 &&
                    noteBookingIndex === -1)
                ) {
                  this.bookingData.push(value)
                }
              })
            }
          }
        })
      })
    })
    this.processBookingStatus()
  }

  processBookingStatus() {
    entries(this.bookingData).forEach((entry: any) => {
      const [, value] = entry
      const serviceStart = this.mainProduct(value)
        ? this.mainProduct(value)![0].item.serviceStart
        : null
      const serviceEnd = this.mainProduct(value)
        ? this.mainProduct(value)![0].item.serviceEnd
        : null
      const productName = value.description
      if (
        this.mainProduct(value)
          ? this.mainProduct(value)![0].item.status !== 'CANCELLED'
          : null
      ) {
        this.bookingFullStatus.push({
          id: value.id,
          title: productName,
          start: serviceStart,
          end: serviceEnd,
          ref: value.reference,
        })
      }
    })
    if (this.bookingFullStatus.length === 0) {
      this.bookingFullStatus.push({
        id: 'no-results',
        title: 'You currently have no notifications.',
      })
    }
  }

  mainProduct(booking: BookingOutput) {
    return booking
      ? booking.productReservations
          .filter(
            (item: Reservation) =>
              item.price.amount &&
              item.product.provider &&
              item.product.externalId
          )
          .map((item: Reservation) => {
            return {
              item,
            }
          })
          .sort((a: any, b: any) => b.item.price.amount - a.item.price.amount)
      : null
  }

  logout() {
    this.$store.commit('account/accountDetails/resetState')
    this.$store.commit('booking/paymentProcessing/resetState')
    this.$store.commit('booking/productDetails/resetState')
    this.$store.commit('booking/passengers/resetState')
    this.$store.commit('updateSku', null)
  }

  mounted() {
    this.processBookingNotifications()
    this.notifications =
      this.bookingFullStatus &&
      this.bookingFullStatus.length &&
      this.bookingFullStatus[0].id !== 'no-results'
        ? this.bookingFullStatus.length
        : 0
  }

  @Watch('processNotifications')
  onNotificationProcessing() {
    if (this.processNotifications) {
      this.processBookingNotifications()
      this.notifications =
        this.bookingFullStatus &&
        this.bookingFullStatus.length &&
        this.bookingFullStatus[0].id !== 'no-results'
          ? this.bookingFullStatus.length
          : 0
    }
  }

  @Watch('overlay')
  onOverlayChange() {
    if (!this.overlay.status) {
      this.showNotifications = false
    }
  }
}
