
import Vue from 'vue'
import { mapState } from 'vuex'
import Component from 'vue-class-component'
import UserNavigation from '~/components/account/UserNavigation.vue'
import UserNavigationMobile from '~/components/account/UserNavigationMobile.vue'
import { Overlay, User } from '~/store/account/accountDetails'

@Component({
  components: { UserNavigation, UserNavigationMobile },
  computed: {
    ...mapState('account/accountDetails', ['user', 'overlay']),
  },
})
export default class AppHeader extends Vue {
  readonly user?: User
  readonly overlay!: Overlay

  toggleOverlay() {
    if (!this.overlay.status) {
      this.$store.commit('account/accountDetails/updateOverlay', {
        overlayStatus: true,
        overlayType: 'menu',
      })
    } else {
      this.$store.commit('account/accountDetails/updateOverlay', {
        overlayStatus: true,
        overlayType: 'menu',
      })
    }
  }
}
