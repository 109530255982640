
import Vue from 'vue'
import { mapState } from 'vuex'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import AppHeader from '~/layouts/AppHeader.vue'
import AppFooter from '~/layouts/AppFooter.vue'
import ActiveBooking from '~/components/ActiveBooking.vue'
import ToastAlert from '~/components/ToastAlert.vue'
import { AlertState } from '~/store/systemAlerts'
import { Overlay } from '~/store/account/accountDetails'

Component.registerHooks(['head'])
@Component({
  components: {
    AppHeader,
    AppFooter,
    ToastAlert,
    ActiveBooking,
  },
  computed: {
    ...mapState(['sku', 'step', 'systemAlerts']),
    ...mapState('account/accountDetails', ['loginStatus', 'overlay']),
    ...mapState('booking/paymentProcessing', ['confirmedStatus', 'processing']),
    ...mapState('account/paymentProcessing', ['accountProcessing']),
  },
})
export default class AppDefault extends Vue {
  readonly confirmedStatus!: string
  readonly systemAlerts!: AlertState
  readonly loginStatus!: boolean
  readonly accountProcessing!: boolean
  readonly processing!: boolean
  readonly overlay!: Overlay
  readonly step!: number
  readonly sku!: string
  response: any = ''

  head() {
    return {
      bodyAttrs: {
        class: this.overlay && this.overlay.status ? 'overflow-hidden' : '',
      },
    }
  }

  manageOverlay() {
    if (this.overlay.status && this.overlay.type !== 'menu') {
      this.$store.commit('account/accountDetails/updateOverlay', {
        overlayStatus: false,
        overlayType: '',
      })
    }
  }

  toggleOverlay() {
    if (!this.overlay.status) {
      this.$store.commit('account/accountDetails/updateOverlay', {
        overlayStatus: true,
        overlayType: '',
      })
    } else {
      this.$store.commit('account/accountDetails/updateOverlay', {
        overlayStatus: false,
        overlayType: '',
      })
    }
  }

  mounted() {
    const gaDataLayer = document.createElement('script')
    gaDataLayer.text =
      'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}'
    document.head.appendChild(gaDataLayer)
    this.$store.commit('booking/productDetails/stopLoading')
    this.manageOverlay()
    try {
      this.$store.commit('booking/paymentProcessing/updateProcessing', false)
      const path = this.$route
      if (
        (path.query.sku && path.query.sku !== this.sku) ||
        (path.query.sku && this.step === 4 && this.confirmedStatus)
      ) {
        this.$store.commit('updateSku', path.query.sku)
        this.$store.commit('booking/productDetails/startLoading')
        this.$store.commit('booking/paymentProcessing/resetState')
        this.$store.commit('booking/productDetails/resetState')
        this.$store.commit('setStep', 1)
        this.$store.dispatch('booking/productDetails/initProduct', {
          config: this.$config,
          sku: path.query.sku,
        })
      } else {
        this.$store.commit('booking/productDetails/stopLoading')
      }
    } catch (e) {
      this.response = e
    }
  }

  @Watch('step')
  onStepChange() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  @Watch('loginStatus')
  onUserChange() {
    if (!this.loginStatus) {
      this.$router.push('/')
    }
  }
}
