import { format } from 'date-fns'
import { mapState } from 'vuex'

export const mixin = {
  rkIdGenerate(customId) {
    return customId || Date.now() + Math.floor(Math.random() * 100)
  },
  rkTimestamp(date) {
    const output = !date ? new Date() : new Date(date)
    return format(output, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
  },
  rkFormatDate(date) {
    const output = !date ? new Date() : new Date(date)
    return format(output, 'dd/MM/yyyy')
  },
  rkAddDaysToDate(date, days) {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + days)
    return newDate
  },
  rkSubtractDaysFromDate(date, days) {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() - days)
    return newDate
  },
  rkMonthsBetweenDates(start, end) {
    let months
    months = (end.getFullYear() - start.getFullYear()) * 12
    months -= start.getMonth()
    months += end.getMonth()
    return months <= 0 ? 0 : months
  },
  rkNumberOfNights(startDate, endDate) {
    const start = new Date(startDate)
    const end = new Date(endDate)
    const cal = end.getTime() - start.getTime()
    return cal / (1000 * 3600 * 24)
  },
  // Open one item at a time.
  //
  rkAccordionToggleSingle(target, id) {
    Array.prototype.forEach.call(target, function (item, index) {
      if (index === id) {
        item.classList.add('show', 'active')
      } else {
        item.classList.remove('show', 'active')
      }
    })
  },
  // Open multiple items.
  //
  rkAccordionToggle(target, id) {
    Array.prototype.forEach.call(target, function (item, index) {
      if (index === id) {
        if (!item.classList.contains('active-accordion')) {
          item.classList.add('show', 'active-accordion')
        } else {
          item.classList.remove('show', 'active-accordion')
        }
      } else {
        return false
      }
    })
  },
  mapStatesTwoWay(namespace, states, updateCb) {
    const mappedStates = mapState(namespace, states)
    const res = {}
    for (const key in mappedStates) {
      res[key] = {
        set(update) {
          updateCb.call(this, { [key]: update })
        },
        get() {
          return mappedStates[key].call(this)
        },
      }
    }
    return res
  },
  rkSetUtcDate(date) {
    if (!date) return null

    const d = new Date(date)
    d.setHours(0, 0, 0, 0)
    const userTimezoneOffset = d.getTimezoneOffset() * 60000

    const final = new Date(
      d.getTime() + userTimezoneOffset * Math.sign(userTimezoneOffset)
    )

    if (d.getTimezoneOffset() > 0) {
      final.setUTCHours(0, 0, 0, 0)
      return final
    }

    return final
  },
  rkGetUtcDate(date) {
    if (!date) return null

    const d = new Date(date)
    if (d.getTimezoneOffset() < 0) {
      d.setHours(0, 0, 0, 0)
    }

    const userTimezoneOffset = d.getTimezoneOffset() * 60000

    const final = new Date(
      d.getTime() + userTimezoneOffset * Math.sign(userTimezoneOffset)
    )

    if (d.getTimezoneOffset() > 0) {
      final.setUTCHours(0, 0, 0, 0)
      return final
    }

    return final
  },
  rkFormatDateString(isoString) {
    if (!isoString) return null
    const [datePart] = isoString.split('T')
    const [year, month, day] = datePart.split('-')
    return `${day}/${month}/${year}`
  },
  rkFormatLocaleString(isoString) {
    if (!isoString) return null
    const [datePart] = isoString.split('T')
    const [year, month, day] = datePart.split('-')
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    return `${day} ${monthNames[parseInt(month) - 1]} ${year}`
  },
  // For customer data management, as the passenger format isn't supplied..
  //
  passengerDataOutput(data, lead, id, loginToken) {
    return {
      temporaryId: id,
      name: data.name ? data.name : '',
      firstName: data.firstName ? data.firstName : '',
      lastName: data.lastName ? data.lastName : '',
      knownAs: data.knownAs ? data.knownAs : '',
      email: data.email ? data.email : '',
      sex: data.sex ? data.sex : '',
      dateOfBirth: data.dateOfBirth ? data.dateOfBirth : null,
      id: data.id ? data.id : '',
      telephone: data.telephone ? data.telephone : '',
      address: {
        line1: data.address.line1 ? data.address.line1 : '',
        line2: data.address.line2 ? data.address.line2 : '',
        line3: data.address.line3 ? data.address.line3 : '',
        city: data.address.city ? data.address.city : '',
        state: data.address.state ? data.address.state : '',
        country: data.address.country ? data.address.country : '',
        postalCode: data.address.postalCode ? data.address.postalCode : '',
      },
      // Show's the notes fields for logged in users on the booking form
      notes: [
        {
          title: 'Experience',
          label:
            'Please provide details of your experience relevant to your trip',
          content: '',
          isRequired: true,
        },
        {
          title: 'Fitness',
          label: 'Please provide details of your fitness',
          content: '',
          isRequired: true,
        },
        {
          title: 'Dietary Requirements',
          label: 'Any specific Dietary Requirements?',
          content: '',
          isRequired: true,
        },
        {
          title: 'Medical Requirements',
          label: 'Any specific Medical Requirements?',
          content: '',
          isRequired: true,
        },
        {
          title: 'Next Of Kin',
          label:
            'Please provide the name, phone number and their relationship to you',
          content: '',
          isRequired: true,
        },
      ],
      isLead: lead,
      token: loginToken.token || null,
      customerId: data.id || null,
    }
  },
}
