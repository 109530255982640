
import Vue from 'vue'
import { mapState } from 'vuex'
import { Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import Btn from '~/components/Btn.vue'
import { User } from '~/store/account/accountDetails'
import { Product } from '~/store/booking/productDetails'

@Component({
  components: { Btn },
  computed: {
    ...mapState('booking/productDetails', ['product']),
    ...mapState('account/accountDetails', ['user']),
  },
})
export default class ActiveBooking extends Vue {
  readonly product!: Product
  readonly user?: User

  activeBooking: boolean = false

  get sku() {
    return this.product ? this.product.id : null
  }

  removeActiveBooking() {
    this.$store.commit('booking/paymentProcessing/resetState')
    this.$store.commit('booking/productDetails/resetState')
    this.$store.commit('booking/passengers/resetState')
    this.$store.commit('updateSku', null)
    this.activeBooking = false
  }

  @Watch('$route', { immediate: true, deep: true })
  onRouteChange() {
    const route = this.$route
    this.activeBooking =
      (route.name !== 'booking' &&
        this.user &&
        this.user.id.length > 0 &&
        this.product &&
        this.product.id.length > 0) ||
      false
  }
}
