import axios from 'axios'
// eslint-disable-next-line import/named
import { MutationTree } from 'vuex'
import { entries } from 'lodash'
import { Booking, Transaction, TransactionStatus } from '~/store/lib/actions'
import NuxtRuntimeConfig = global.NuxtRuntimeConfig

export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Timestamp: any
}

export interface ProductPaxAssignment {
  provider: Scalars['String']
  sku: Scalars['String']
  passengers: any
  // Remove from incoming Customer data.
  //
  passenger?: any
}

export interface Customer {
  name?: Scalars['String']
  email: Scalars['String']
  telephone: Scalars['String']
  address: {
    line1: Scalars['String']
    line2?: Scalars['String']
    line3?: Scalars['String']
    city: Scalars['String']
    state: Scalars['String']
    country: Scalars['String']
    postalCode: Scalars['String']
  }
  // Remove from incoming Customer data.
  //
  id?: Scalars['ID']
  temporaryId?: null
  token?: Scalars['String']
  knownAs?: Scalars['String']
  dateOfBirth?: Scalars['Timestamp']
  sex?: Scalars['String']
  isLead?: Scalars['Boolean']
  notes?: any
}

export interface Passenger {
  name: Scalars['String']
  dateOfBirth: Scalars['Timestamp']
  email?: Scalars['String']
  telephone?: Scalars['String']
  // Remove from incoming Passenger data.
  //
  knownAs?: Scalars['String']
  temporaryId?: Scalars['String']
  isLead?: Scalars['Boolean']
  address?: {
    line1?: Scalars['String']
    line2?: Scalars['String']
    line3?: Scalars['String']
    city?: Scalars['String']
    state?: Scalars['String']
    country?: Scalars['String']
    postalCode?: Scalars['String']
  }
  token?: Scalars['String']
}

export interface BookingData {
  organizationId: Scalars['String']
  passengers: Passenger[]
  products: ProductPaxAssignment[]
  customer?: Customer
}

export interface PaymentState {
  products: ProductPaxAssignment[] | null
  passengers: Passenger[] | null
  customer: Customer | null
  booking: Booking | null
  bookingAttempts: Scalars['Int']
  processing: Scalars['Boolean']
  transaction: TransactionStatus | null
  altBilling: Scalars['Boolean'] | false
  confirmedStatus: Scalars['String'] | null
  activePayment: Scalars['Boolean'] | null
  paymentType: Scalars['String'] | null
}

export const state = (): PaymentState => ({
  products: null,
  passengers: null,
  customer: null,
  booking: null,
  bookingAttempts: 0,
  processing: false,
  transaction: null,
  altBilling: false,
  confirmedStatus: null,
  activePayment: false,
  paymentType: 'deposit-payment',
})

const mutations: MutationTree<PaymentState> = {
  /**
   * Reset the payment details
   * @param state
   */
  resetState(state: PaymentState) {
    state.products = null
    state.passengers = null
    state.customer = null
    state.booking = null
    state.bookingAttempts = 0
    state.processing = false
    state.transaction = null
    state.altBilling = false
    state.confirmedStatus = null
    state.paymentType = 'deposit-payment'
  },
  processCustomer(state: PaymentState, customer: Customer) {
    // This is for processing only and is reset
    // each time the payment/booking is run.
    //
    state.customer = customer
    delete state.customer.name
    delete state.customer.id
    delete state.customer.temporaryId
    delete state.customer.knownAs
    delete state.customer.dateOfBirth
    delete state.customer.sex
    delete state.customer.token
    delete state.customer.isLead
    // Removes the notes from the customer that are added in global.js
    delete state.customer.notes
  },
  processProducts(
    state: PaymentState,
    {
      products,
      passengers,
    }: { products: ProductPaxAssignment[]; passengers: Passenger[] }
  ) {
    // This is for processing only and is reset
    // each time the payment/booking is run.
    //
    const productsItems: ProductPaxAssignment[] = []
    entries(products).forEach((entry: any) => {
      const [index, value] = entry
      delete value.passenger
      // No longer using '*' to allocate all passengers.
      //
      if (value.passengers.includes('*')) {
        // Set lead to zero.
        //
        value.passengers = [0]
      }
      // Process existing allocation.
      //
      if (parseInt(index) === 0) {
        // Manually generate the additional main products.
        //
        entries(passengers).forEach((subEntry: any) => {
          const paxIndex = parseInt(subEntry[0])
          productsItems.push({
            provider: value.provider,
            sku: value.sku,
            passengers: [paxIndex],
          })
        })
      } else {
        // Manually generate the additional options.
        //
        entries(passengers).forEach((paxEntry: any) => {
          const paxIndex = parseInt(paxEntry[0])
          entries(value.passengers).forEach((subEntry: any) => {
            const [, subValue] = subEntry
            if (subValue === paxIndex) {
              productsItems.push({
                provider: value.provider,
                sku: value.sku,
                passengers: [paxIndex],
              })
            }
          })
        })
      }
    })
    state.products = productsItems
  },
  processPassengers(state: PaymentState, passengers: Passenger[]) {
    // This is for processing only and is reset
    // each time the payment/booking is run.
    //
    state.passengers = passengers
    entries(passengers).forEach((entry: any) => {
      const [, value] = entry
      delete value.temporaryId
      delete value.knownAs
      delete value.isLead
      delete value.address
      delete value.token
      delete value.id
      delete value.customerId
      delete value.name

      entries(value.notes).forEach((entry: any) => {
        const [, note] = entry
        delete note.isRequired
        delete note.label
      })
    })
  },
  updateBooking(state: PaymentState, booking: Booking) {
    state.booking = booking
  },
  updateBookingAttempts(state: PaymentState) {
    state.bookingAttempts = state.bookingAttempts + 1
  },
  updateProcessing(state: PaymentState, processing: boolean) {
    state.processing = processing
  },
  updateTransaction(state: PaymentState, transaction: TransactionStatus) {
    state.transaction = transaction
  },
  changeBillingStatus(state: PaymentState, altBilling: boolean) {
    state.altBilling = altBilling
  },
  updateConfirmedStatus(state: PaymentState, confirmedStatus: string) {
    state.confirmedStatus = confirmedStatus
  },
  updateActivePaymentStatus(state: PaymentState, status: boolean) {
    state.activePayment = status
  },
  updatePaymentType(state: PaymentState, type: string) {
    state.paymentType = type
  },
  resetPaymentType(state: PaymentState) {
    state.paymentType = 'full-payment'
  },
  resetBooking(state: PaymentState) {
    state.booking = null
  },
  resetBookingAttempts(state: PaymentState) {
    state.bookingAttempts = 0
  },
}

const actions = {
  async createBooking(
    { commit }: { commit: Function },
    {
      config: { apiHost },
      data,
      token,
    }: { config: NuxtRuntimeConfig; data: BookingData; token: string | null }
  ) {
    const bookPath = apiHost + '/book'
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      }
      delete data.customer
      await axios.post(bookPath, data, { headers }).then((response) => {
        const data: Booking = {
          id: response.data.booking.id,
          reference: response.data.booking.reference,
          token: response.data.token.token,
          notes: response.data.notes,
        }
        commit('updateBooking', data)
      })
    } else {
      await axios.post(bookPath, data).then((response) => {
        const data: Booking = {
          id: response.data.booking.id,
          reference: response.data.booking.reference,
          customer: response.data.booking.customer,
          token: response.data.token.token,
          notes: response.data.notes,
        }
        commit('updateBooking', data)
      })
    }
  },
  async createTransaction(
    { commit }: { commit: Function },
    {
      config: { apiHost },
      booking,
      transaction,
    }: { config: NuxtRuntimeConfig; booking: Booking; transaction: Transaction }
  ) {
    const bookPath = apiHost + '/book/' + booking.id + '/transactions'
    await axios.post(bookPath, transaction).then((response) => {
      const data = {
        id: response.data.id,
        state: 'Created',
      }
      commit('updateTransaction', data)
    })
  },
  async getTransaction(
    { commit }: { commit: Function },
    {
      config: { apiHost },
      transactionId,
    }: { config: NuxtRuntimeConfig; transactionId: string }
  ) {
    const bookPath = apiHost + '/transactions/' + transactionId
    await axios.get(bookPath).then((response) => {
      const data = {
        id: transactionId,
        state: response.data.status,
      }
      commit('updateTransaction', data)
    })
  },
  async confirmBooking(
    { commit }: { commit: Function },
    {
      config: { apiHost },
      bookingId,
      transactionId,
    }: { config: NuxtRuntimeConfig; bookingId: string; transactionId: string }
  ) {
    const bookPath = apiHost + '/confirm'
    const data = {
      bookingId,
      transactionId,
    }
    try {
      await axios.post(bookPath, data).then((response) => {
        const statusCode = response.status
        if (statusCode === 200 || statusCode === 201 || statusCode === 202) {
          commit('updateConfirmedStatus', 'OK')
        } else {
          commit('updateConfirmedStatus', 'NOT OK')
        }
      })
    } catch (e: any) {
      const statusCode = e.response.status
      if (statusCode === 422) {
        commit('updateConfirmedStatus', 'NOT OK')
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
