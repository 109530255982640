
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class Button extends Vue {
  @Prop() readonly path?: string
  @Prop() readonly link?: boolean
  @Prop() readonly type?: string
  @Prop() readonly styleOpts?: string
  @Prop() readonly baseStyle?: string

  get style() {
    return this.baseStyle ? this.styleEditor(this.baseStyle) : ''
  }

  get setType() {
    return this.type || null
  }

  styleEditor(style: string) {
    let output = ''
    switch (style) {
      case 'light':
        output = 'rk-btn--light'
        break
      case 'dark':
        output = 'rk-btn--dark'
        break
      case 'red':
        output = 'rk-btn--red'
        break
      case 'secondary':
        output = 'rk-btn--secondary'
        break
      case 'success':
        output = 'rk-btn--success'
        break
      case 'outlined':
        output = 'rk-btn--outlined'
        break
      case 'plain':
        output = 'rk-btn--plain'
        break
      case 'read-more':
        output = 'rk-btn--read-more'
        break
    }
    return output
  }

  click() {
    this.$emit('processClick', this.setType)
  }
}
