
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Alert } from '~/store/systemAlerts'

@Component
export default class ToastAlert extends Vue {
  @Prop() readonly alert!: Alert

  clearAlert() {
    this.$store.commit('systemAlerts/dismissAlert', alert)
  }
}
