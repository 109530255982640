// eslint-disable-next-line import/named
import { MutationTree } from 'vuex'

export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Timestamp: any
}

export interface TemporaryId {
  temporaryId: Scalars['String']
}

export interface Validation {
  id: Scalars['String']
  invalid: Scalars['Boolean']
}

export type Passenger = {
  __typename?: 'Passenger'
  temporaryId: Scalars['String']
  name?: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  knownAs: Scalars['String']
  email: Scalars['String']
  sex: Scalars['String']
  dateOfBirth: Scalars['Timestamp']
  id: Scalars['ID']
  telephone: Scalars['String']
  address: {
    line1: Scalars['String']
    line2: Scalars['String']
    line3: Scalars['String']
    city: Scalars['String']
    state: Scalars['String']
    country: Scalars['String']
    postalCode: Scalars['String']
  }
  isLead: Scalars['Boolean']
  notes: any
}

export type Customer = {
  __typename?: 'Customer'
  id?: Scalars['ID']
  temporaryId: Scalars['String']
  name?: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  knownAs?: Scalars['String']
  sex: Scalars['String']
  email: Scalars['String']
  dateOfBirth?: Scalars['Timestamp']
  telephone?: Scalars['String']
  address: {
    line1: Scalars['String']
    line2: Scalars['String']
    line3: Scalars['String']
    city: Scalars['String']
    state: Scalars['String']
    country: Scalars['String']
    postalCode: Scalars['String']
  }
}

export interface PassengerState {
  passengers: Array<Partial<Passenger> & TemporaryId>
  customer: Customer | null
  validation: Validation[]
  existingCustomer: Scalars['Boolean']
}

export const state = (): PassengerState => ({
  passengers: [],
  customer: null,
  validation: [],
  existingCustomer: false,
})

const mutations: MutationTree<PassengerState> = {
  /**
   * Reset the passenger state
   * @param state
   **/
  resetState(state: PassengerState) {
    state.passengers = []
    state.customer = null
    state.validation = []
    state.existingCustomer = false
  },
  set(
    state: PassengerState,
    passengers: Array<Partial<Passenger> & TemporaryId>
  ) {
    state.passengers = passengers
  },
  // Manage passengers.
  //
  addPassenger(
    state: PassengerState,
    passenger: Partial<Passenger> & TemporaryId
  ) {
    state.passengers.push(passenger)
  },
  updateExistingCustomerId(state: PassengerState, id: string) {
    state.passengers[0].temporaryId = id
  },
  updateLeadPassenger(
    state: PassengerState,
    passenger: Partial<Passenger> & TemporaryId
  ) {
    // Force passenger update.
    //
    state.passengers!.splice(0, 0, passenger)
    state.passengers!.splice(0, 1)
    state.passengers[0] = passenger
  },
  removePassenger(state: PassengerState, id: string) {
    const idx = state.passengers.findIndex((p) => p.temporaryId === id)
    state.passengers!.splice(idx, 1)
  },
  addCustomer(state: PassengerState, customer: Customer) {
    state.customer = customer
  },
  updateCustomer(state: PassengerState, customer: Customer) {
    state.customer = customer
  },
  existingCustomerStatus(state: PassengerState, status: boolean) {
    state.existingCustomer = status
  },
  // Update passenger note.
  //
  updatePassengerNotes(
    state: PassengerState,
    {
      temporaryId,
      content,
      index,
    }: { temporaryId: string; content: string; index: number }
  ) {
    // @ts-ignore
    const notes = state.passengers.find(
      (p) => p.temporaryId === temporaryId
    ).notes
    notes[index].content = content
  },
  // Update passenger value.
  //
  updatePassengerStringValue(
    state: PassengerState,
    {
      temporaryId,
      value,
      target,
    }: { temporaryId: string; value: string; target: string }
  ) {
    // @ts-ignore
    state.passengers.find((p) => p.temporaryId === temporaryId)[target] = value
    if (state.customer?.temporaryId === temporaryId) {
      switch (target) {
        case 'firstName':
          state.customer.firstName = value
          break
        case 'lastName':
          state.customer.lastName = value
          break
        case 'knownAs':
          state.customer.knownAs = value
          break
        case 'dateOfBirth':
          state.customer.dateOfBirth = value
          break
        case 'sex':
          state.customer.sex = value
          break
        case 'email':
          state.customer.email = value
          break
        case 'telephone':
          state.customer.telephone = value
          break
      }
    }
  },
  // Update passenger value adjoined.
  //
  updatePassengerStringValueAdjoined(
    state: PassengerState,
    {
      temporaryId,
      value,
      target1,
      target2,
    }: { temporaryId: string; value: string; target1: string; target2: string }
  ) {
    // @ts-ignore
    state.passengers.find((p) => p.temporaryId === temporaryId)[target1][
      target2
    ] = value
    if (state.customer?.temporaryId === temporaryId) {
      switch (target2) {
        case 'line1':
          state.customer.address.line1 = value
          break
        case 'line2':
          state.customer.address.line2 = value
          break
        case 'line3':
          state.customer.address.line3 = value
          break
        case 'city':
          state.customer.address.city = value
          break
        case 'state':
          state.customer.address.state = value
          break
        case 'country':
          state.customer.address.country = value
          break
        case 'postalCode':
          state.customer.address.postalCode = value
          break
      }
    }
  },
  // Changing passenger telephone number.
  //
  updatePassengerNumberValue(
    state: PassengerState,
    {
      temporaryId,
      value,
      target,
    }: { temporaryId: string; value: number; target: string }
  ) {
    // @ts-ignore
    state.passengers.find((p) => p.temporaryId === temporaryId)![target] = value
  },
  updateValidation(state: PassengerState, validate: Validation) {
    const idx = state.validation!.findIndex((v) => v.id === validate.id)
    if (idx !== -1) {
      state.validation!.splice(idx, 1)
      state.validation!.push(validate)
    } else {
      state.validation!.push(validate)
    }
  },
  removeValidation(state: PassengerState, id: string) {
    const idx = state.validation.findIndex((v) => v.id === id)
    state.validation!.splice(idx, 1)
  },
}

const actions = {}

const getters = {
  getPassengers: (state: any): Passenger => state.passengers,
  getCustomer: (state: any): Customer => state.customer,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
