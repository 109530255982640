import Vue from 'vue'
import Vuex from 'vuex'
import country from './country'
import systemAlerts from './systemAlerts'
import account from '~/store/account'
import booking from '~/store/booking'

Vue.config.devtools = true

const createStore = () => {
  // eslint-disable-next-line import/no-named-as-default-member
  return new Vuex.Store({
    strict: false,

    state: () => ({
      sku: null,
      step: 1,
    }),
    mutations: {
      updateSku(state, sku) {
        state.sku = sku
      },
      setStep(state, step) {
        state.step = step
      },
    },
    modules: {
      account,
      country,
      booking,
      systemAlerts,
    },
    actions: {
      async nuxtServerInit() {},
    },
  })
}

export default createStore
