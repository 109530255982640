
import Vue from 'vue'
import { mapState } from 'vuex'
import Component from 'vue-class-component'
import { User } from '~/store/account/accountDetails'
import Btn from '~/components/Btn.vue'

@Component({
  components: { Btn },
  computed: {
    ...mapState('account/accountDetails', ['user', 'token']),
  },
})
export default class AppHeader extends Vue {
  readonly token!: string
  readonly user?: User

  logout() {
    this.$store.commit('account/accountDetails/resetState')
    this.$store.commit('booking/paymentProcessing/resetState')
    this.$store.commit('booking/productDetails/resetState')
    this.$store.commit('booking/passengers/resetState')
    this.$store.commit('updateSku', null)
  }
}
