// eslint-disable-next-line import/named
import { MutationTree } from 'vuex'
import { Context } from '@nuxt/types'

export interface Alert {
  id: number
  message: string
  timeout: number
  type?: string
}

export interface AlertState {
  alerts: Alert[]
}

const state = (): AlertState => ({ alerts: [] })

const mutations: MutationTree<AlertState> = {
  activeAlerts(state: AlertState, alerts: Alert[]) {
    state.alerts = alerts
  },
  setAlert(
    state: AlertState,
    { alert, extra }: { alert: Alert; extra: string }
  ) {
    if (!alert.message.includes(extra) && extra) {
      alert.message = alert.message + extra
    }
    state.alerts!.push(alert)
  },
  dismissAlert(state: AlertState, alert: Alert) {
    const idx = state.alerts!.findIndex((n) => n.id === alert.id)
    state.alerts!.splice(idx, 1)
  },
}

const actions = {
  async nuxtServerInit({ commit }: { commit: Function }, context: Context) {
    await commit('activeAlerts', context)
  },
  submitAlert(
    { commit }: { commit: Function },
    { alert, extra }: { alert: Alert; extra: string }
  ) {
    commit('setAlert', { alert, extra })
    setTimeout(() => {
      commit('dismissAlert', alert)
    }, alert.timeout)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
