import Vue from 'vue'
import { lowerCase, toNumber } from 'lodash'

Vue.filter('price', function (value, code, digits) {
  const penceConversion = value / 100
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: code,
    minimumFractionDigits: digits,
  })
  return formatter.format(penceConversion)
})

Vue.filter('number', function (input) {
  return toNumber(input)
})

Vue.filter('capitalize', function (text) {
  const output = lowerCase(text)
  return output.replace(/^\w/, (c) => c.toUpperCase())
})
